import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
import * as i1 from '@angular/platform-browser';
class SafePipe {
  constructor(sanitizer) {
    this.sanitizer = sanitizer;
  }
  transform(value, type = 'html') {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
  static {
    this.ɵfac = function SafePipe_Factory(t) {
      return new (t || SafePipe)(i0.ɵɵdirectiveInject(i1.DomSanitizer, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "safe",
      type: SafePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SafePipe, [{
    type: Pipe,
    args: [{
      name: 'safe',
      standalone: true
    }]
  }], () => [{
    type: i1.DomSanitizer
  }], null);
})();
class StripHtmlPipe {
  transform(value) {
    if (typeof window !== 'undefined') {
      const element = document.createElement('div');
      element.innerHTML = value;
      return element.innerText;
    } else {
      return value;
    }
  }
  static {
    this.ɵfac = function StripHtmlPipe_Factory(t) {
      return new (t || StripHtmlPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "stripHtml",
      type: StripHtmlPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StripHtmlPipe, [{
    type: Pipe,
    args: [{
      name: 'stripHtml',
      standalone: true
    }]
  }], null, null);
})();
class TruncatePipe {
  transform(value, max = 20, stuffix = '...', truncateBy) {
    if (truncateBy !== 'words') {
      return value.length > max ? value.substring(0, max - stuffix.length) + stuffix : value;
    } else {
      return value.split(' ').slice(0, max).join(' ') + stuffix;
    }
  }
  static {
    this.ɵfac = function TruncatePipe_Factory(t) {
      return new (t || TruncatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "truncate",
      type: TruncatePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TruncatePipe, [{
    type: Pipe,
    args: [{
      name: 'truncate',
      standalone: true
    }]
  }], null, null);
})();
class UrlRewritePipe {
  transform(value) {
    if (typeof value !== 'string') {
      return '';
    }
    return value.replace(/ /g, '-').replace(/&/g, 'en').replace(/!/g, '').replace(/\./g, '').replace(/,/g, '').replace(/\?/g, '').replace(/\+/g, '').toLowerCase();
  }
  static {
    this.ɵfac = function UrlRewritePipe_Factory(t) {
      return new (t || UrlRewritePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "urlRewrite",
      type: UrlRewritePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UrlRewritePipe, [{
    type: Pipe,
    args: [{
      name: 'urlRewrite',
      standalone: true
    }]
  }], null, null);
})();
class StringUtility {
  static StripHTML(value) {
    if (typeof window !== 'undefined') {
      const element = document.createElement('div');
      element.innerHTML = value;
      return element.innerText;
    } else {
      return value;
    }
  }
  static UrlRewrite(value) {
    if (typeof value !== 'string') {
      return '';
    }
    return value.replace(/ /g, '-').replace(/&/g, 'en').replace(/!/g, '').replace(/\./g, '').replace(/,/g, '').replace(/\?/g, '').replace(/\+/g, '').toLowerCase();
  }
  static Truncate(value, limit = 140, endsWith = '...') {
    return value.length > limit ? value.substring(0, limit - endsWith.length) + endsWith : value;
  }
}
const pipes = [SafePipe, StripHtmlPipe, TruncatePipe, UrlRewritePipe];
class TextUtilityModule {
  static {
    this.ɵfac = function TextUtilityModule_Factory(t) {
      return new (t || TextUtilityModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TextUtilityModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextUtilityModule, [{
    type: NgModule,
    args: [{
      exports: [...pipes],
      imports: [...pipes]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SafePipe, StringUtility, StripHtmlPipe, TextUtilityModule, TruncatePipe, UrlRewritePipe };
