import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Html5PlayerVars } from '@teamfoster/sdk/html5-video-player';
import { Url } from 'url';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() imageUrl: string | undefined;
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() text: string = '';
  @Input() cssClass: string = '';
  @Input() buttons: { text: string; url: string; routerLink: string[] }[] = [];
  @Input() skewBottom: boolean = false;
  @Input() videoBg: string | undefined;
  @Input() playerVars: Html5PlayerVars = {
    autoplay: true,
    controls: false,
    muted: true,
    loop: true,
  };
  @Output() inView = new EventEmitter<any>();
  inviewPort = false;

  constructor() {}

  inViewChange(e: boolean) {
    this.inviewPort = e;
    if (e) {
      this.inView.emit(null);
    }
  }
}
