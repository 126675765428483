<header
  class="header"
  [class.skew--bottom]="skewBottom"
  [ngClass]="cssClass"
  (fstrInViewport)="inViewChange($event)"
  [once]="false"
  [threshold]="0.25"
>
  <div class="container" [class.container-size-sm]="!imageUrl">
    <main class="row py-5 align-items-center">
      <section class="col-lg-7">
        <h2 class="h2 pb-4" [innerHTML]="title"></h2>
        <p class="text-muted type-body-sm" [innerHTML]="text"></p>
        @for (button of buttons; track button; let i = $index) {
          @if (button.routerLink?.length) {
            <a
              class="button button--primary mt-4 me-4"
              [class.rounded-pill]="i < 2"
              [class.button-link]="i >= 2"
              [routerLink]="button.routerLink"
              [attr.target]="!button.routerLink ? '_blank' : null"
            >
              @if (i < 2) {
                <app-icon class="icon-play-circle" anchor="icon-play-circle"></app-icon>
              }
              <span>{{ button.text }}</span>
              @if (i >= 2) {
                <app-icon class="icon-chevron-right" anchor="icon-chevron-right"></app-icon>
              }
            </a>
          }
          @if (button.url?.length && !button.routerLink?.length) {
            <a
              [href]="button.url"
              class="button button--primary mt-4 me-4"
              [class.rounded-pill]="i < 2"
              [class.button-link]="i >= 2"
              [attr.target]="!button.routerLink ? '_blank' : null"
            >
              @if (i < 2) {
                <app-icon class="icon-play-circle" anchor="icon-play-circle"></app-icon>
              }
              <span>{{ button.text }}</span>
              @if (i >= 2) {
                <app-icon class="icon-chevron-right" anchor="icon-chevron-right"></app-icon>
              }
            </a>
          }
        }
      </section>
      @if (imageUrl || videoBg) {
        <figure class="col-lg-5 header__crop mt-4 mt-md-0">
          @if (imageUrl) {
            <img [src]="imageUrl | resize: 1980" />
          }
          @if (inviewPort && videoBg) {
            <fstr-html5-video class="page-header__video" [playerVars]="playerVars" [src]="videoBg"></fstr-html5-video>
          }
        </figure>
      }
    </main>
  </div>
</header>
