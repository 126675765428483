import { CommonModule, JsonPipe } from '@angular/common';
import * as i0 from '@angular/core';
import { input, output, signal, viewChild, computed, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
const _c0 = ["videoEl"];
function Html5VideoComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "footer", 3)(1, "button", 4);
    i0.ɵɵlistener("click", function Html5VideoComponent_Conditional_3_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.togglePlay());
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 4);
    i0.ɵɵlistener("click", function Html5VideoComponent_Conditional_3_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.toggleAudio());
    });
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r2.playingVideo$() ? "Pause" : "Play");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r2.audioMuted$() ? "Audio on" : "Audio off");
  }
}
class Html5VideoComponent {
  constructor() {
    this.src = input.required();
    this.playerVars = input({});
    this.poster = input();
    this.playsinline = input(true);
    this.showButtons = input(false); // show Controls in the player
    this.load = output();
    this.ready = output(); //
    this.onPlay = output({
      alias: 'play'
    });
    this.onPause = output({
      alias: 'pause'
    });
    this.playingVideo$ = signal(false);
    this.audioMuted$ = signal(false);
    this.isLoaded$ = signal(false);
    this.ref = viewChild.required('videoEl');
    this.playerDefaults = {
      autoplay: false,
      controls: true,
      muted: false,
      loop: false
    };
    this.playerConfig$ = computed(() => {
      const playerVars = this.playerVars();
      return Object.assign(this.playerDefaults, playerVars);
    });
    this.showControls$ = computed(() => {
      return this.showButtons() && !this.playerConfig$().controls;
    });
  }
  log(e) {
    console.log(e);
  }
  async ngOnInit() {
    // this.playerVars().set(Object.assign(this.playerDefaults, this.playerVars));
  }
  videoLoad($event) {
    this.load.emit($event);
    this.isLoaded$.set(true);
  }
  play() {
    this.ref().nativeElement.play();
  }
  pause() {
    this.ref().nativeElement.pause();
  }
  playing($event) {
    const videoEl = this.ref().nativeElement;
    this.audioMuted$.set(videoEl.muted);
    this.playingVideo$.set(true);
  }
  pauseVideo($event) {
    this.playingVideo$.set(false);
  }
  togglePlay() {
    const videoEl = this.ref().nativeElement;
    if (videoEl.paused) {
      this.play();
    } else {
      this.pause();
    }
  }
  toggleAudio() {
    const videoEl = this.ref().nativeElement;
    videoEl.muted = !videoEl.muted;
    this.audioMuted$.set(videoEl.muted);
  }
  static {
    this.ɵfac = function Html5VideoComponent_Factory(t) {
      return new (t || Html5VideoComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: Html5VideoComponent,
      selectors: [["fstr-html5-video"]],
      viewQuery: function Html5VideoComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.ref, _c0, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        src: [1, "src"],
        playerVars: [1, "playerVars"],
        poster: [1, "poster"],
        playsinline: [1, "playsinline"],
        showButtons: [1, "showButtons"]
      },
      outputs: {
        load: "load",
        ready: "ready",
        onPlay: "play",
        onPause: "pause"
      },
      exportAs: ["fstrHtml5Video"],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 9,
      consts: [["videoEl", ""], [1, "fstr-html5-video"], ["playsinline", "playsinline", 1, "fstr-html5-video__media", 3, "loadeddata", "canplay", "playing", "play", "pause", "loop", "muted", "autoplay", "poster", "controls", "src"], [1, "d-flex", "gap-2", "fstr-html5-video__controls"], [1, "button", 3, "click"]],
      template: function Html5VideoComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 1)(1, "video", 2, 0);
          i0.ɵɵlistener("loadeddata", function Html5VideoComponent_Template_video_loadeddata_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.videoLoad($event));
          })("canplay", function Html5VideoComponent_Template_video_canplay_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.ready.emit($event));
          })("playing", function Html5VideoComponent_Template_video_playing_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.playing($event));
          })("play", function Html5VideoComponent_Template_video_play_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onPlay.emit($event));
          })("pause", function Html5VideoComponent_Template_video_pause_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            ctx.onPause.emit($event);
            return i0.ɵɵresetView(ctx.pauseVideo($event));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, Html5VideoComponent_Conditional_3_Template, 5, 2, "footer", 3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("is--loaded", ctx.isLoaded$());
          i0.ɵɵadvance();
          i0.ɵɵproperty("loop", ctx.playerConfig$().loop)("muted", ctx.playerConfig$().muted)("autoplay", ctx.playerConfig$().autoplay)("poster", ctx.poster(), i0.ɵɵsanitizeUrl)("controls", ctx.playerConfig$().controls)("src", ctx.src(), i0.ɵɵsanitizeUrl);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.showControls$() ? 3 : -1);
        }
      },
      dependencies: [CommonModule],
      styles: [".fstr-html5-video[_ngcontent-%COMP%]{width:100%;height:0;overflow:hidden;padding-bottom:56.25%;position:relative;display:block}.fstr-html5-video__controls[_ngcontent-%COMP%]{position:absolute;bottom:0;left:0}  .fstr-html5-video__media{width:100%;height:100%;position:absolute;object-fit:cover;inset:0}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Html5VideoComponent, [{
    type: Component,
    args: [{
      selector: 'fstr-html5-video',
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'fstrHtml5Video',
      standalone: true,
      imports: [JsonPipe, CommonModule],
      template: "<div class=\"fstr-html5-video\" [class.is--loaded]=\"isLoaded$()\">\r\n  <video\r\n    class=\"fstr-html5-video__media\"\r\n    [loop]=\"playerConfig$().loop\"\r\n    [muted]=\"playerConfig$().muted\"\r\n    [autoplay]=\"playerConfig$().autoplay\"\r\n    [poster]=\"poster()\"\r\n    [controls]=\"playerConfig$().controls\"\r\n    [src]=\"src()\"\r\n    playsinline=\"playsinline\"\r\n    (loadeddata)=\"videoLoad($event)\"\r\n    (canplay)=\"ready.emit($event)\"\r\n    (playing)=\"playing($event)\"\r\n    (play)=\"onPlay.emit($event)\"\r\n    (pause)=\"onPause.emit($event); pauseVideo($event)\"\r\n    #videoEl\r\n></video>\r\n\r\n  @if(showControls$()) {\r\n    <footer class=\"d-flex gap-2 fstr-html5-video__controls\">\r\n      <button (click)=\"togglePlay()\" class=\"button\">{{ playingVideo$() ? 'Pause' : 'Play' }}</button>\r\n      <button (click)=\"toggleAudio()\" class=\"button\">{{this.audioMuted$() ? 'Audio on' : 'Audio off' }}</button>\r\n    </footer>\r\n  }\r\n\r\n</div>\r\n\r\n\r\n\r\n",
      styles: [".fstr-html5-video{width:100%;height:0;overflow:hidden;padding-bottom:56.25%;position:relative;display:block}.fstr-html5-video__controls{position:absolute;bottom:0;left:0}::ng-deep .fstr-html5-video__media{width:100%;height:100%;position:absolute;object-fit:cover;inset:0}\n"]
    }]
  }], null, null);
})();
class Html5VideoPlayerModule {
  static {
    this.ɵfac = function Html5VideoPlayerModule_Factory(t) {
      return new (t || Html5VideoPlayerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: Html5VideoPlayerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [Html5VideoComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Html5VideoPlayerModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [Html5VideoComponent],
      exports: [Html5VideoComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Html5VideoComponent, Html5VideoPlayerModule };
