<footer class="footer">
  <ul class="footer__nav-list pt-3 pb-3 px-3 type-body-xs">
    <li class="footer__nav-list__item">{{ year }} &copy; {{ 'meta-titel-suffix' | fromDictionary }}</li>
    @for (item of menuItems; track item) {
      <li class="footer__nav-list__item">
        <a [routerLink]="item.url" class="button-link button--primary">{{ item.menuTitle || item.header.title }}</a>
      </li>
    }
    <li class="footer__nav-list__item">
      <button (click)="openCookie()" class="button-link button--primary">{{ 'cookie-settings' | fromDictionary }}</button>
    </li>
  </ul>
  <app-json-ld [json]="schema"></app-json-ld>
</footer>
