<div class="grid-overlay">
  <div class="container">
    <div class="row grid-showcase">
      @for (number of gridColumns; track number) {
        <div class="col-1">
          <div class="grid-showcase__inner">
            {{ number + 1 }}
          </div>
        </div>
      }
    </div>
  </div>
</div>