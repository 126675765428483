import * as i0 from '@angular/core';
import { input, output, Directive, NgModule } from '@angular/core';
class InViewportDirective {
  constructor(element) {
    this.element = element;
    this.delay = input(500);
    this.once = input(true);
    this.threshold = input(0);
    this.fstrInViewport = output();
    this._isIntersecting = false;
  }
  ngAfterViewInit() {
    if (typeof window !== 'undefined' && window['IntersectionObserver'] !== undefined) {
      setTimeout(() => {
        const callback = e => {
          if (e[0].isIntersecting) {
            this._isIntersecting = true;
            this.fstrInViewport.emit(this._isIntersecting);
            if (this.once()) {
              this._observer?.disconnect();
            }
          } else if (this._isIntersecting && !e[0].isIntersecting) {
            this._isIntersecting = false;
            this.fstrInViewport.emit(this._isIntersecting);
          }
        };
        this._observer = new IntersectionObserver(callback, {
          threshold: [this.threshold()]
        });
        this._observer.observe(this.element.nativeElement);
      }, this.delay());
    }
  }
  ngOnDestroy() {
    this._observer?.disconnect();
  }
  static {
    this.ɵfac = function InViewportDirective_Factory(t) {
      return new (t || InViewportDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: InViewportDirective,
      selectors: [["", "fstrInViewport", ""]],
      inputs: {
        delay: [1, "delay"],
        once: [1, "once"],
        threshold: [1, "threshold"]
      },
      outputs: {
        fstrInViewport: "fstrInViewport"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InViewportDirective, [{
    type: Directive,
    args: [{
      selector: '[fstrInViewport]',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class InViewModule {
  static {
    this.ɵfac = function InViewModule_Factory(t) {
      return new (t || InViewModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: InViewModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InViewModule, [{
    type: NgModule,
    args: [{
      exports: [InViewportDirective],
      imports: [InViewportDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { InViewModule, InViewportDirective };
