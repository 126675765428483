<div class="lang-switcher" #dropdownpanel>
  <h2 class="sr-only">Choose language</h2>
  <button class="lang-switcher__toggle" (click)="toggleDropdown()">
    <img class="lang-icon" [src]="getFlag(activeLanguage.code)" [alt]="activeLanguage.code" />
    <span class="sr-only">Active language: {{ activeLanguage.name }}</span>
  </button>

  @if (dropdownOpen) {
    <ul class="lang-switcher__dropdown lang-list">
      @for (lang of languages; track lang) {
        <li class="lang-list__item">
          <button (click)="setLanguage(lang)" class="lang-button" [class.is--active]="lang.code === activeLang">
            <img class="lang-icon" [src]="getFlag(lang.code)" [alt]="lang.code" />
            <span>
              <span class="sr-only">Set language to:</span>
              {{ lang.name }}
            </span>
          </button>
        </li>
      }
    </ul>
  }
</div>
